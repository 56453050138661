// JORIS SKIN <- FE SKIN <- BF SKIN <- JIRAFA SKIN
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  //color: #FFF;
}

#root {
  padding-top: 0;
}

.btn,
.loading-spinner-content {
  text-transform: uppercase;
}

// NAV

.navbar-light {
  //background: transparent !important;
  //transition: background 0.2s;
  background-color: rgba($color: $lpblue, $alpha: 0.9) !important;
  color: $white;
  // body[data-scrolled] & {
  //   background-color: $white !important;
  // }
}

body.ticket-listing-page,
body.ticket-detail-page {
  //#main {background-color: $joristan;}
}

// .product-listing-page .navbar-brand {
//   opacity: 0;
//   transform: translateY(-10px);
//   transition: all 0.1s;
//   transition-delay: 0;
// }

// body[data-scrolled] {
//   .navbar-brand {
//     opacity: 1;
//     transition-delay: 0.2s;
//     transform: translateY(0);
//   }
// }

#header {
  box-shadow: $box-shadow-xl;
  .main-menu {
    // flex-grow: 0;
    @include media-breakpoint-up(md) {
      // margin-right: auto;
    }
  }
}

#header .navbar {
  padding: 1rem;
  font-size: $h5-font-size;

  a.nav-link.highlight {
    color: #fff;
  }
}

#header .navbar .navbar-brand {
  img {
    height: 2rem;
    max-height: 2rem;

    @include media-breakpoint-up(md) {
      height: 4rem;
      max-height: 4rem;
    }

    &:hover,
    &:focus {
      filter: opacity(0.66);
    }
  }
}

.navbar .hamburger {
  color: $white !important;
  @include media-breakpoint-down(sm) {
    padding-left: 0px;
  }
}

.navbar .hamburger.is-active .hamburger-inner,
.navbar .hamburger.is-active .hamburger-inner:after,
.navbar .hamburger.is-active .hamburger-inner:before {
  background-color: $lpgreen !important;
}

.navbar .hamburger-inner,
.navbar .hamburger-inner:after,
.navbar .hamburger-inner:before {
  height: 1px;
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
  transition: all 0.2s;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

.simple-cart .item-count.badge-info  {
  background-color: $danger;
}

.offcanvas-collapse {
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    background-color: $white !important;
    a {
      color: #fff;
    }
    a.active,
    a:hover {
      color: $gray-200;
      //font-style: italic;
    }
    // font-family: $font-family-heading;
    font-size: $h5-font-size;
    text-transform: uppercase;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
}

.offcanvas-collapse {
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// OC CART

.offcanvas-collapse-right {
  background-color: rgba($color: $white, $alpha: 0.97) !important;
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  h5 {
    font-size: $h5-font-size;
    text-transform: uppercase;
    color: $gray-900 !important;
  }
}

// MAKI

.maki {
  @extend .p-3;
  .card-title {
    text-align: left;
    .title {
      //text-transform: uppercase;
      font-size: $h5-font-size;
      color: $lpgreen;
      @extend .text-truncate;
    }

    .sub-title {
      font-size: smaller;
      @extend .mb-2;
    }
  }
  &:hover {
    box-shadow: $box-shadow-xl;
    transition: all 0.15s ease-in-out;
  }
}

// NIGIRI

.nigiri.card {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid $gray-300;
  .nigiri-content {
    padding: 1.25rem 0;
    padding-top: 0;
    margin-top: 0.2rem;
    color: $lpblue;
    @include media-breakpoint-down(md) {
      display: table;
    }
  }
  .nigiri-date {
    font-size: $h3-font-size;
    //color: $white;
  }
  .nigiri-body {
    font-size: $h2-font-size;
    border-left: 0;
    //text-transform: uppercase;
    font-weight: bold;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      line-height: 2rem;
    }
  }
  .badge {
    z-index: 2;
    transform: rotate(2.5deg);
    font-size: 0.8em;
  }
  .title {
    font-size: 0.6em;
    margin-bottom: 0;
    //color: $white;
    font-family: $font-family-sans-serif !important;
  }
  .subtitle {
    font-size: 0.5em;
    margin-bottom: 0;
    color: $gray-500;
    font-weight: normal;
    @include media-breakpoint-down(md) {
      line-height: 1rem;
      margin-bottom: 0.5rem;
    }
  }
  .location {
    font-family: $font-family-sans-serif !important;
    font-size: $h3-font-size !important;
    color: $lpgreen;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }
}

// PDP

body.ticket-detail-page {
  .card {
    border: 0;
  }
  .card.article-title {
    // background-color: $white;
    color: $lpgreen !important;
    h1 {
      font-size: $h1-font-size;
      color: $lpgreen !important;
    }
    .sub-title {
      padding-top: 0.8rem;
    }
  }
  .product-info-bar {
    //i {color: $agorange !important;}
    //box-shadow: none;
  }
  .container.detail-page-inner {
    padding-left: 0;
    padding-right: 0;
  }
}

.detail-page {
  h1 {
    margin-top: 0;
    // color: $lpyellow;
    text-transform: uppercase;
    font-size: $h2-font-size;
    .sub-title {
      // color: $lpblue;
    }
  }
  .product-header-title {
    line-height: 1.4;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: $lptan;
}

// .blurry-background-image:before {
//   background-image: none;
// }

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

// FOOTER

#footer {
  background-color: $lpblue;
  color: $lpgreen;
  a {
    color: $lpgreen;
  }
  border-bottom: 10px solid $primary;
}

#product-listing {
  padding-top: 2rem;
}

.hero-slider {
  margin-bottom: 0;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

// FIXES

.tt-sticky-top {
  top: 0;
}

// MODAL

.shopping-finisher-modal {
  .variant-chooser h5 {
    font-size: $font-size-base;
  }
  .variant-chooser .variant-chooser-select {
    font-size: smaller;
    font-weight: normal;
  }
}

// landingpage-lp

body.landing-page-lp #main {
  background-image: url('./assets/images/bg-tape.png'), linear-gradient(to bottom, $lpyellow, lighten($lpyellow, 30%));
  background-position: center center, center center;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;

  background-color: $lpyellow;

  img.box {
    transition: all 0.2s;
  }
  img.box:hover {
    transform: rotate(-2deg);
    transition: all 0.2s;
  }
}

// fix for add to cart button scrolled out of sight on iPhones.
// spinner is hidden so that add to cart button is aligned.

@include media-breakpoint-only(xs) {
  table.variant-listing {
    display: grid;
    grid-template-columns: auto;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }

    thead {
      display: none;
    }

    tbody,
    tr {
      display: inherit;
      margin-bottom: 1rem;
      background-color: $white !important;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    tr {
      grid-template-rows: auto;
      box-shadow: $box-shadow;
      //border: 1px solid $gray-200;
      th {
        padding-bottom: 0.1rem;
      }
      td:first-of-type {
        border-top: 0;
        padding-top: 0.1rem;
      }
    }

    tbody tr td,
    tbody tr th {
      text-align: left !important;
    }

    fieldset.number-input {
      button,
      input {
        width: stretch;
        width: -webkit-fill-available;
        width: fill-available;
      }
    }

    colgroup {
      display: none;
    }

    div.invisible.spinner-border {
      display: none !important;
    }

    button.add-product {
      width: 100%;
      max-width: 100%;
      span {
        display: inline !important;
      }
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// special branding 'Was zum Festivar?'

.product-detail-257 article.product-detail,
.product-detail-257 article.product-detail .product-content {
  color: white;
  background-color: linear-gradient(hsla(356, 82, 44, 1), hsla(356, 82, 24, 1)) !important;
  background: linear-gradient(hsla(356, 82, 44, 1), hsla(356, 82, 24, 1)) !important;
  padding-top: 0px;
}
