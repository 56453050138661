h1,
h2 {
  font-family: $lpfont !important;
  color: $lpgreen !important;
}

.btn-primary {
  font-family: $lpfont !important;
}

.start-page {
  img {
    max-width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.ticket-listing-page,
.product-detail {
  .btn-primary {
    color: $white;
    background-color: $lpgreen;
    border-color: $lpgreen;
  }
}

//FILTER BUTTONS OVER TICKET LISTING

.listing-buttons-container {
  .btn {
    border-radius: 0;
    font-size: $h4-font-size;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#header {
  background-color: $white;
  background-image: url('./assets/redesign0524/grunge-weiss.png');
  background-position: center center;

  .nav-link {
    color: $lpgreen !important;
    font-family: $lpfont !important;
    //font-size: 0.8em;
  }
  .navbar-brand {
    img {
      margin: 0 auto !important;
    }
  }
}

#footer {
  border-bottom: 10px solid $lpgreen;
  background-color: transparent;
  // background: url('./assets/redesign0524/risskante-weiss.png');
  background-position: center top;
  background-repeat: repeat-x;
  // padding-top: 10rem;
}

#banner {
  background-color: $black;
  color: $white;
}

// BACKGROUNDS

body.product-listing-page,
body.ticket-listing-page,
body.landing-page {
  background-image: url('./assets/redesign0524/grunge-weiss.png');
  h1 {
    // color: $white !important;
    font-size: 3rem;
    text-transform: uppercase;
  }
}

// MAKI

.sushi-container {
  margin-bottom: 2rem;
}
.maki.card {
  @extend .p-3;
  //@extend .mb-4;
  @extend .text-truncate;
  background-color: $white;
  box-shadow: $box-shadow-xl;

  .card-body {
    margin-bottom: 0;
  }

  .card-title {
    @extend .text-truncate;
    text-align: left;
    .title {
      //text-transform: uppercase;
      font-size: $h4-font-size;
      font-family: $headings-font-family;
      color: $lpgreen;
      overflow: hidden;
      font-family: $headings-font-family;
    }
    .sub-title {
      font-size: smaller;
      @extend .mb-2;
    }
  }
  .price {
    font-weight: bold;
  }
  .former-price {
    text-decoration: line-through;
    display: inline-block;
    //font-size: 13px;
    //line-height: 1.6rem;
    color: $lpred !important;
  }
  .tag {
    background-color: $info;
    position: absolute;
    bottom: 10.5rem;
    right: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 4rem;
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
  }
  &:hover {
    transition: all 0.15s ease-in-out;
  }
}

// NIGIRI

.nigiri.card {
  background-color: $white;
  box-shadow: $box-shadow;
  margin-bottom: 0 !important;

  border: 0.5px solid $lpgreen;
  //border-bottom: 1px solid $gray-300;
  .nigiri-content {
    grid-template-columns: 15rem 2fr auto;
    padding: 1.25rem;
    margin-top: 0.2rem;
    color: $lpblue;
    @include media-breakpoint-down(md) {
      display: table;
    }
  }
  .nigiri-date {
    font-size: $h3-font-size;
    font-family: $lpfont;
    text-align: left;
    align-items: inherit;
    //color: $white;
  }
  .nigiri-body {
    font-size: $h2-font-size;
    border-left: 0;
    //text-transform: uppercase;
    font-weight: bold;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      line-height: 2rem;
    }
  }
  .badge {
    z-index: 2;
    transform: rotate(2.5deg);
    font-size: 0.8em;
  }
  .title {
    font-size: 0.6em;
    margin-bottom: 0;
    //color: $white;
    font-family: $font-family-sans-serif !important;
  }
  .subtitle {
    font-size: 0.5em;
    margin-bottom: 0;
    color: $gray-500;
    font-weight: normal;
    @include media-breakpoint-down(md) {
      line-height: 1rem;
      margin-bottom: 0.5rem;
    }
  }
  .location {
    font-family: $lpfont !important;
    font-size: $h3-font-size !important;
    color: $lpgreen;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
      font-size: 1.25rem !important;
    }
  }
}
